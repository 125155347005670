<template>
  <div
    class="mb-2 mx-n3 bg-gray-100 px-3 py-2 table-legend"
  >
    <div v-if="!noSearch">
      <p>
        <strong>Ricerca libera</strong>
      </p>
      <b-input-group class="input--alt-style">
        <b-form-input
          v-model="searchKeywords"
          type="text"
          @update="updateSearchString(searchKeywords)"
        />
        <b-input-group-append>
          <b-button
            class="btn-info ms-1"
            @click="searchAction"
          >
            <font-awesome-icon icon="binoculars" class="fa-fw" />
          </b-button>
        </b-input-group-append>
      </b-input-group>
    </div>
    <div :class="filterClasses">
      <div>
        <p>
          <strong>Filtra per</strong>
        </p>
      </div>
      <div class="d-flex flex-wrap">
        <button-toggle
          v-for="(btn, index) in toggleButtons"
          :key="'btn_'+index"
          :icon="btn.icon"
          :label="btn.label"
          :action="(value) => manageFilter(value, btn.action)"
          :variant="btn.variant"
          has-checkbox
        />
      </div>
    </div>
    <!--    <div-->
    <!--      v-if="!!(this.$slots.filters || [])[0]"-->
    <!--    >-->
    <div
      v-if="!!(this.$slots.filters || [])[0]"
      class="mt-2"
    >
      <slot name="filters" />
      <div class="d-flex border-top pt-2">
        <b-button
          class="btn-info ms-auto"
          @click="searchAction"
          size="sm"
        >
          <font-awesome-icon icon="binoculars" />
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
const ButtonToggle = () => import('@/components/helpers/ButtonToggle.vue');

export default {
  name: 'TableFilters',
  components: { ButtonToggle },
  props: {
    toggleButtons: Array,
    manageFilter: Function,
    updateSearchString: Function,
    searchAction: Function,
    noSearch: Boolean,
  },
  data() {
    return {
      searchKeywords: '',
      customFilters: false,
    };
  },
  computed: {
    filterClasses() {
      return this.noSearch ? '' : 'mt-2';
    },
  },
  methods: {},
};
</script>

<style scoped>

</style>
